
import { defineComponent } from 'vue';
import "vue3-circle-progress/dist/circle-progress.css";
import CircleProgress from "vue3-circle-progress";
export default defineComponent({
  name : 'StudyElementFocus',
  components:{
    CircleProgress
  },
  props:{
    element:{
      type: Object,
      default:{productType:String}
    },
    contractId: [String, Number]
  },
  data(){
    return{
    }
  },
  methods:{
    checkPreviewRedirection(){
      const testId = this.element.testHash 
      this.$emit('open-test-preview', testId)
    },
    openCalendar(){
      this.$router.push({name:'watfocusconfig', params:{ contractId: this.contractId }})
    }
  }
})
