import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ProgressNum", {'completed':$props.count==$props.total && $props.total != 0}])
  }, [
    _createElementVNode("p", null, [
      _createElementVNode("span", null, _toDisplayString($props.count), 1),
      _createTextVNode("/" + _toDisplayString($props.total), 1)
    ])
  ], 2))
}