
import { defineComponent } from 'vue';
import store from '@/store'
import StudyElementActionBox from './StudyElementActionBox.vue'
import { mapGetters } from 'vuex'
import ProgressBar from './ProgressBar.vue'
import QuestionsExportPDF from './QuestionsExportPDF.vue'
import VideoViewer from './VideoViewer.vue'
import { jsPDF } from "jspdf";
import Swal from 'sweetalert2'
import { saveAs } from 'file-saver';
import ProgressModal from '@/components/StudyCreation/ProgressModal.vue'
export default defineComponent({
  name : 'StudyElement',
  components:{
    ProgressBar,
    StudyElementActionBox,
    QuestionsExportPDF,
    VideoViewer,
    ProgressModal
  },
  props:{
    element:{
      type: Object,
      default:{productType:String}
    },
    singleElement:{
      type:Boolean,
      default: false
    },
    contractId:[Number, String],
    onlyTestPdf:{
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'provinces',
      'regions',
      'nielsenAreas',
      'authToken'
    ])
  },
  data(){
    return{
      //common data : title, 3 dates, price, elements
      // Survey-Corporate-WATUX : go, duration, preview, watmonitor, download PDF, Excel, download report, dashboard report
      // WATLAB-EL : community login link, download report, total of activities
      // WATfocus : community login link, download report, videos
      testTypes:["WAT_SURVEY","WAT_CORPORATE","WAT_UX"],
      communityTypes:["WAT_LAB","EXPERIENCE_LAB", "WAT_FOCUS"],
      test:null,
      navigationRules:null,
      downloadingPDF: false
    }
  },
  methods:{
    isTest(){
      return this.element.type == 'TEST'
    },
    isCommunity(){
      return this.element.type == 'COMMUNITY'
    },
    downloadReport(){
      if(!this.isReportDownloadEnabled()) return
      this.createStatistics('REPORT_DOWNLOAD')
      const fakeLink = document.createElement("a");
      fakeLink.style.display = "none";
      document.body.appendChild(fakeLink);
      fakeLink.href = this.element.reportUrl
      fakeLink.setAttribute("download", this.$t('suite_report')+" "+ this.element.type.toLowerCase());
      fakeLink.click();
      window.URL.revokeObjectURL(fakeLink.href);
      document.body.removeChild(fakeLink);
    },
    async downloadTestPDF(){
      let vm = this
      this.createStatistics('TEST_PDF_DOWNLOAD')
      Swal.fire({
        title: vm.$t('suite_study_generate_pdf'),
        allowOutsideClick: false
      })
      Swal.showLoading()
      const testId = this.element.testHash != undefined ? this.element.testHash : this.element.elementId 
      await store.dispatch('getTest',{testId:testId})
      .then((data) => {
        this.test = data
      })
      await store.dispatch('getTestNavigationRules',{testId:testId})
      .then((data) => {
        this.navigationRules = data
        this.downloadingPDF = true
      })
    },
    pdfDownloaded(){
      this.downloadingPDF = false
      Swal.close()
    },
    checkPreviewRedirection(){
      if(this.isCommunity()){
        this.createStatistics('COMMUNITY_DIRECT_LOGIN')
        const communityBaseUrl = this.element.platform == 'WAT_LAB' ? process.env.VUE_APP_WATLAB_URL : process.env.VUE_APP_EL_URL
        const communityRedirectUrl = communityBaseUrl + '/c/' + this.element.urlToken + '/backoffice?clientAuthToken=' + this.authToken +'&fromSuite=1'
        window.location.href = communityRedirectUrl
      }
      else{
        const testId = this.element.testHash != undefined ? this.element.testHash : this.element.elementId 
        this.$emit('open-test-preview', testId)
      }
    },
    async downloadExcel(){
      if(!this.element.showExcelClientArea) return
      this.$emit("excel-event", true);
      await store.dispatch('fetchCombinedExcel', {
        testId: this.element.testHash,
      })
      .then((data) => {
        if(data != undefined){
          let FileSaver = require('file-saver');
          let blob = new Blob([ data ], {type: 'application/vnd.ms-excel'})
          saveAs(blob, this.$t('suite_study_test_results')+' '+this.element.elementId+'.xlsx', false);
        } else {
            Swal.fire("","El fichero no está disponible aún, se genera cada 2 horas","warning")
        }
        this.$emit("excel-event", false);
      })
    },
    isReportDownloadEnabled(){
      return this.element.reportUrl != undefined && this.element.reportUrl != null && this.element.reportUrl != '' && this.element.reportUrl != 'null'
    },
    isExcelDownloadEnabled(){
      return this.element.showExcelClientArea
    },
    isWatMonitorEnabled(){
      return this.element.showWATMonitorClientArea
    },
    createStatistics(concept: String){
      store.dispatch('createStatisticsProject',{concept:concept,contractId: this.contractId, projectId:this.element.elementId})
    }
  }
})
