
export default {
  name : 'ProgressNum',

  props:{
    total:{
      type:Number,
      default: 0
    },
    count:{
      type: Number,
      default:0
    }
  }
}
