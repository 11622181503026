
import { defineComponent } from 'vue';
import ProgressNum from './ProgressNum';
export default defineComponent({
  name : 'ProgressBar',
  components:{
    ProgressNum
  },
  props:{
    total:{
      type:Number,
      default: 0
    },
    count:{
      type: Number,
      default:0
    },
    hideNumbers:{
      type: Boolean,
      default: false
    },
    progress:{
      type: Number,
      default: null
    }
  },
  methods:{
    getProgress(){
      return this.progress == null ? this.count*100/this.total : this.progress
    }
  }
})
