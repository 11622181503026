
import { defineComponent } from 'vue';
export default defineComponent({
  name : 'CheckBox',
  props:{
    text:{
      type:String,
      default:" "
    },
    contrast:{
      type: Boolean,
      default: false
    },
    modelValue: Boolean,
  },
  emits: ['update:modelValue', 'change'],
  watch: {
    value (newValue) {
     this.$emit('update:modelValue', newValue)
   }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(newValue: any) {
        this.$emit('update:modelValue', newValue)
      }
    }
  }
})
