
import { defineComponent } from 'vue';
export default defineComponent({
  name : 'VideoViewer',
  props:{
    videos:Array
  },
  data(){
    return{
      isGalleryOpen: false,
      currentVideoIndex:0,
    }
  },
  methods:{
    getCapture(){
      for(let index in this.videos){
        let video = <HTMLVideoElement> document.getElementById('video'+index);
        if(video != null) video.currentTime = 0.2;
      }
    },
    changeCurrentVideo(index: any){
      this.pauseCurrentVideo()
      this.currentVideoIndex = index;
    },
    close(){
      this.pauseCurrentVideo()
      this.isGalleryOpen = false
      $('.StudyCard').css('z-index', 1);
    },
    open(){
      this.isGalleryOpen = true
      $('.StudyCard').css('z-index', 'auto');
    },
    pauseCurrentVideo(){
      const video = <HTMLVideoElement> document.querySelector("#video-repro"+this.currentVideoIndex)!;
      if(video != null) video.pause();
    }
  },
  mounted(){
    this.getCapture()
  }
})
