
export default {
  name : 'StatusCircle',

  props:{
    type:{
      type:String,
      default: 'DRAFT'
    }
  }
}
