
import { defineComponent } from 'vue';
export default defineComponent({
  name : 'StudyElementActionBox',
  props:{
    icon:String,
    text:String,
    disabled:{
      type: Boolean,
      default:false
    },
    hoverIcon:String,
  },
})
