<template>
  <div id="QuestionsExportPDF" class="QuestionsExportPDF">
    <img class="Header--WatLogo" id="HeaderImage" src="../assets/img/pdf/header.png" alt="">
    <img class="Header--WatLogo" id="background" src="../assets/img/pdf/background.png" alt="">
  </div>
</template>

<script>
import { jsPDF } from "jspdf";
import 'jspdf/dist/polyfills.es.js'
export default {
  name : 'QuestionsExportPDF',
  props:{
    test:{
      type: Object,
    },
    navigationRules:{
      type: Array,
    },
  },
  data(){
    return{
      heightPosition: 50,
      pageNum:1
    }
  },
  methods:{
    async generatePDF(){
      var pdf = new jsPDF('p', 'px', 'a4');
      pdf.addImage($('#background')[0], 'png', 0, 0,pdf.getPageWidth(),pdf.getPageHeight())
      this.generatePageNum(pdf)
      this.generateHeader(pdf)
      this.updateHeightPosition(pdf,30)
      this.generateTasks(pdf)
      this.generateNavRules(pdf)
      pdf.save('Cuestionario - ' + this.test.test.title + '.pdf');
      this.$emit('pdf-downloaded')
    },
    generatePageNum(pdf){
      pdf.setFillColor(238, 238, 238);
      pdf.setLineWidth(0.5);
      pdf.circle(pdf.getPageWidth()-5, pdf.getPageHeight()-5, 40, 'F');
      pdf.text(this.pageNum.toString(), pdf.getPageWidth()-15, pdf.getPageHeight()-15)
    },
    generateHeader(pdf){
      pdf.setFont(undefined, 'bold').setFontSize(20)
      pdf.text(this.test.test.title,18,this.heightPosition, {maxWidth:pdf.getPageWidth() - 20})
      pdf.setFillColor(255, 0, 0);
      pdf.setLineWidth(0.5);
      pdf.triangle(10, 41, 10, 49, 17, 45, 'F');
      pdf.addImage($('#HeaderImage')[0], 'png', 0, 0, 700, 24)
    },
    async generateTasks(pdf){
      let index = 0
      for(let task of this.test.testTasks){
        pdf.setFont(undefined, 'bold').setFontSize(16)
        pdf.text('Tarea ' + (Number.parseInt(index) + 1) + ' : ' + task.name,10,this.heightPosition, {maxWidth:pdf.getPageWidth() - 20})
        this.generateQuestions(pdf, task)
        this.updateHeightPosition(pdf,20)
        pdf.setFillColor(211, 211, 211);
        pdf.setLineWidth(0.5);
        pdf.rect(10, this.heightPosition, pdf.getPageWidth() - 20, 0, 'F');
        this.updateHeightPosition(pdf,40)
        index++
      }
    },
    async generateQuestions(pdf, task){
      let index = 0
      for(let question of task.questions){
        this.updateHeightPosition(pdf,20)
        pdf.setFont(undefined, 'normal')
        pdf.setFillColor(255, 21, 100);
        pdf.setFontSize(9)
        pdf.roundedRect(10, this.heightPosition-8, pdf.getTextWidth(question.type) + 4, 10, 2, 2, 'F')
        pdf.setTextColor('white')
        pdf.text(question.type, 12, this.heightPosition)
        pdf.setTextColor('black')
        pdf.setFontSize(13)
        this.updateHeightPosition(pdf,12)
        const questionText = task.taskNum + '.' + (Number.parseInt(index) + 1) + '- ' + question.text.replace( /(<([^>]+)>)/ig, '')
        pdf.text(questionText, 10, this.heightPosition, {maxWidth:pdf.getPageWidth() - 20})
        this.updateHeightPosition(pdf, this.calculateNextHeight(pdf, questionText, pdf.getPageWidth() - 20, 15))
        if(question.matrixRadioButton){
          pdf.text('- Elementos', 20, this.heightPosition)
          this.updateHeightPosition(pdf,10)
        }
        if(question.values) this.generateQuestionValues(pdf, question.values)
        if(question.matrixRadioButton) this.generateQuestionRadioButtons(pdf, question.radioOptionTexts)
        index++
      }
    },
    generateQuestionValues(pdf, values){
      let indexValue = 0
      pdf.setFontSize(11)
      for(let value of values){
        const text = value.id + ' - ' + value.value
        pdf.text(text,30,this.heightPosition, {maxWidth:pdf.getPageWidth() - 40})
        this.updateHeightPosition(pdf,this.calculateNextHeight(pdf, text, pdf.getPageWidth() - 40, 10))
        indexValue++
      }
    },
    generateQuestionRadioButtons(pdf, values){
      this.updateHeightPosition(pdf,10)
      pdf.text('- Valores de respuesta', 20, this.heightPosition)
      this.updateHeightPosition(pdf,10)
      pdf.setFontSize(11)
      for(let index in values){
        const text = (Number.parseInt(index)+1) + '- ' + values[index]
        pdf.text(text,30,this.heightPosition, {maxWidth:pdf.getPageWidth() - 50})
        this.updateHeightPosition(pdf,this.calculateNextHeight(pdf, text, pdf.getPageWidth() - 50, 10))
      }
    },
    generateNavRules(pdf){
      if(this.navigationRules != undefined && this.navigationRules != null){
        pdf.setFont(undefined, 'bold').setFontSize(16)
        pdf.text('Reglas de navegación', 10, this.heightPosition)
        pdf.setFont(undefined, 'normal').setFontSize(11)
        this.updateHeightPosition(pdf,20)
        let ruleIndex = 0
        for(let rule of this.navigationRules){
          pdf.text("- Regla " + Number.parseInt(ruleIndex+1),30,this.heightPosition)
          this.updateHeightPosition(pdf,10)
          pdf.text('Condicion: ' + this.getRuleCondition(rule), 40, this.heightPosition, {maxWidth:pdf.getPageWidth() - 50})
          this.updateHeightPosition(pdf,this.calculateNextHeight(pdf,'Condicion: ' + this.getRuleCondition(rule),pdf.getPageWidth() - 50, 10))
          pdf.text('Acción: ' + this.getRuleAction(rule), 40, this.heightPosition, {maxWidth:pdf.getPageWidth() - 50})
          this.updateHeightPosition(pdf,20)
          ruleIndex++
        }
      }
    },
    updateHeightPosition(pdf,value){
      if(this.heightPosition + 100 >= pdf.getPageHeight()){
        pdf.addPage()
        pdf.addImage($('#background')[0], 'png', 0, 0,pdf.getPageWidth(),pdf.getPageHeight())
        this.heightPosition = 10
        this.pageNum++
        this.generatePageNum(pdf)
      }
      this.heightPosition += value
    },
    getRuleCondition(rule){
      let condition = rule.split("then")[0]
      condition = condition.replace("if","Si en la")
      let taskCount = (condition.match(/task/g) || []).length
      let questionCount = (condition.match(/question/g) || []).length
      let operatorDiferent = (condition.match(/!=/g) || []).length
      let operatorExclusiveEqual= (condition.match(/==/g) || []).length
      let operatorSelections = (condition.match(/#/g) || []).length
      let operatorEqual = (condition.match(/=/g) || []).length
      let operatorAnd = (condition.match(/&/g) || []).length
      let operatorOr = (condition.match(/|/g) || []).length
      for(let x = 0;x<taskCount;x++){
        condition = condition.replace("task","tarea ")
      }
      for(let x = 0;x<questionCount;x++){
        condition = condition.replace("-question"," pregunta ")
      }
       for(let x = 0;x<operatorDiferent;x++){
        condition = condition.replace("!="," la respuesta es diferente de ")
      }
         for(let x = 0;x<operatorExclusiveEqual;x++){
        condition = condition.replace("=="," la respuesta tiene solo el valor ")
      }
         for(let x = 0;x<operatorSelections;x++){
        condition = condition.replace("#"," la respuesta tiene el número de selecciones ")
      }
         for(let x = 0;x<operatorEqual;x++){
        condition = condition.replace("="," la respuesta es igual a ")
      }
      for(let x = 0;x<operatorAnd;x++){
        condition = condition.replace("&"," y si en la ")
       }
      for(let x = 0;x<operatorOr;x++){
        condition = condition.replace("|"," o si en la ")
      }
      condition = condition.replace("-option"," opcion ")
      return condition
    },
    getRuleAction(rule){
      let action = rule.split("then")[1]
      let taskCount = (action.match(/task/g) || []).length
      let questionCount = (action.match(/question/g) || []).length
      for(let x = 0;x<taskCount;x++){
        action = action.replace("task","tarea ")
      }
      for(let x = 0;x<questionCount;x++){
        action = action.replace("-question"," pregunta ")
      }

      action = action.replace("cond-show-option","Mostrar")
      action = action.replace("cond-show","Mostrar")
      action = action.replace("jump-to","Saltar a")
      action = action.replace("finish-test","Finalizar")
      action = action.replace("quality-killer","Expulsar por calidad")
      action = action.replace("quality-killer-nosanction","Expulsar por calidad sin sanción")
      action = action.replace("killer","Expulsar")
      action = action.replace("-option"," opcion ")
      return action
    },
    calculateNextHeight(pdf, text, maxWidth, defaultHeight){
      let nextHeight = defaultHeight
      if(pdf.getTextWidth(text) > maxWidth){
        nextHeight = (Math.ceil(pdf.getTextWidth(text) / pdf.getPageWidth()) * pdf.getTextDimensions(text).h) + defaultHeight
      }
      return nextHeight
    },
  },
  mounted(){
     this.generatePDF();
  }
}
</script>
