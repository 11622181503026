import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["StudyElementActionBox", {'disabled':_ctx.disabled}])
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.icon)
    }, null, 2),
    _createElementVNode("p", null, _toDisplayString(_ctx.text), 1),
    (!_ctx.disabled)
      ? (_openBlock(), _createElementBlock("i", {
          key: 0,
          class: _normalizeClass('fas fa-'+_ctx.hoverIcon)
        }, null, 2))
      : _createCommentVNode("", true)
  ], 2))
}