
import { defineComponent } from 'vue';
export default defineComponent({
  name : 'InputSearch',
  props:{
    placeholder:{
      type:String,
      default:""
    },
    modelValue:String,
  },
  data(){
    return{
      value:this.modelValue,
      errorMessage:"",
      error: false
    }
  },
  emits: ['update:modelValue'],
  watch: {
    value (newValue) {
     this.$emit('update:modelValue', newValue)
    }
  },
})
